import { IntlProvider, addLocaleData } from 'react-intl';
import cs from 'react-intl/locale-data/cs';
import messages from '../../i18n/messages';
import { FunctionComponent } from 'react';
// const messages = {'cs-CZ': {}};
addLocaleData([...cs]);

const locale = 'cs-CZ'

export const CustomIntlProvider: FunctionComponent = ({children}) => (
	<IntlProvider locale={locale}  messages={messages[locale]}>
		{children}
	</IntlProvider>
)