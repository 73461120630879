import Router from 'next/router';
import NProgress from 'nprogress';
import { Component } from 'react';

export class RouterProgress extends Component
{
	progressTimeout = null

	render()
	{
		return null;
	}

	componentDidMount()
	{
		NProgress.configure({ trickleSpeed: 200 });
		Router.onRouteChangeStart = this.onRouteChangeStart
		Router.onRouteChangeComplete = this.onRouteChangeComplete
		Router.onRouteChangeError = this.progressDone
	}

	onRouteChangeStart = () =>
	{
		if(this.progressTimeout) clearTimeout(this.progressTimeout)
		this.progressTimeout = setTimeout(() => {
			NProgress.start();
		}, 250);
	}

	onRouteChangeComplete = (url) =>
	{
		this.progressDone()
	}

	progressDone = () =>
	{
		NProgress.done()
		clearTimeout(this.progressTimeout)
		this.progressTimeout = null
	}
}
