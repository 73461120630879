import '../../../common/src/styles/styles.scss';
import $ from 'jquery';
import { NextComponentType, NextPageContext } from 'next';
import { AppContext } from 'next/app';
import { useRouter } from 'next/router';
import nextCookies from 'next-cookies';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { StateProvider } from '../../../common/src/components/ads/StateProvider';
import { CustomIntlProvider } from '../../../common/src/components/service/CustomIntlProvider';
import { RouterProgress } from '../../../common/src/components/service/RouterProgress';
import { ParsedUrlQuery } from 'querystring';
import { CookiesContext } from '../../../common/src/components/service/CookiesContext';
import { DynamicAdblockDetect } from '../../../common/src/components/adblockdetect/DynamicAdblockDetect';
import * as Sentry from '@sentry/node';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

// @ts-ignore
if (process.browser) {
	window.jQuery = $;
}

type Url = {
	pathname: string;
	query: ParsedUrlQuery;
	asPath: string;
};

type Props = {
	Component: NextComponentType<NextPageContext, {}, { url: Url; intl: IntlShape }>;
	pageProps: {};
	cookies: { [cookieName: string]: string };
	err: any;
};

Sentry.init({
	enabled: process.env.NODE_ENV === 'production',
	dsn: publicRuntimeConfig.env.SENTRY_DSN,
	defaultIntegrations: false,
});

const PlayerApp = ({ Component, pageProps, cookies, err }: Props) => {
	const router = useRouter();

	return (
		<>
			<RouterProgress />
			<CustomIntlProvider>
				<CookiesContext.Provider value={cookies}>
					<StateProvider>
						<RenderIntl>
							{(intl: IntlShape) => (
								<Component
									url={{
										pathname: router.pathname,
										query: router.query,
										asPath: router.asPath,
									}}
									intl={intl}
									{...pageProps}
									// Workaround for https://github.com/vercel/next.js/issues/8592
									// @ts-ignore
									err={err}
								/>
							)}
						</RenderIntl>
					</StateProvider>
				</CookiesContext.Provider>
			</CustomIntlProvider>
			<DynamicAdblockDetect message="Vypadá to, že používáte rozšíření prohlížeče pro blokování reklam. Jestli chcete využívat všechny funkce našeho webu jako třeba přehrávání audia nebo videa, bude potřeba ho pro tento web vypnout." />
		</>
	);
};

PlayerApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
	// Page props
	let componentInitialProps = {};
	if (Component.getInitialProps) {
		componentInitialProps = await Component.getInitialProps(ctx);
	}

	const cookies = nextCookies(ctx);

	return {
		pageProps: {
			...componentInitialProps,
		},
		cookies,
	};
};

export default PlayerApp;

interface RenderIntlInterface {
	intl: IntlShape;
	children: (intl: IntlShape) => React.ReactElement<any> | null;
}
const RenderIntl = injectIntl(({ intl, children }: RenderIntlInterface) => children(intl));
